<template>
    <div class="elem">
    <PreLoader v-if="isLoading"></PreLoader>
        <div class="site-wrap" id="home-section">
            <topHeader/>
            <v-card
                class="elem__cards"
            >
                <v-card-title>
                    <v-row>
                        <v-col md="6" cols="6">
                            <h5><b>Beginner Part</b></h5>
                        </v-col>
                        <v-col md="6" cols="6" align="end">
                            <v-btn style="color:#fff;" @click="toHome" color="#f0bd30">Артка кайтуу</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-container fluid>
                    <v-row dense>
                        <v-col
                        v-for="card in cards"
                        :key="card.title"
                        md="4" sm="6" xs="12" cols="12"
                        >
                            <v-card @click="goTo(card.path)">
                                <v-img
                                :src="card.src"
                                class="white--text align-end"
                                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                height="200px"
                                >
                                <v-card-title v-text="card.title"></v-card-title>
                                </v-img>

                                <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn icon>
                                    <v-icon>mdi-heart</v-icon>
                                </v-btn>

                                <v-btn icon>
                                    <v-icon>mdi-bookmark</v-icon>
                                </v-btn>

                                <v-btn icon>
                                    <v-icon @click="sharedialog= true">mdi-share-variant</v-icon>
                                </v-btn>
                                
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
            
            <v-dialog
                v-model="sharedialog"
                width="500"
                >
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                    Privacy Policy
                    </v-card-title>

                    <v-card-text style="font-size:18px;" class="mt-5">
                        <ShareNetwork
                            v-for="network in networks"
                            :network="network.network"
                            :key="network.network"
                            :style="{backgroundColor: network.color,marginLeft: network.mar,borderRadius:network.pad,padding:network.pad}"
                            :url="sharing.url"
                            :title="sharing.title"
                            :description="sharing.description"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                            :twitterUser="sharing.twitterUser"
                        >
                            
                            <i style="margin: 10px 0px 10px 0px;" :class="network.icon"> {{ network.name }}</i>
                        </ShareNetwork>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="sharedialog = false"
                        >
                            I accept
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Footer/>
        </div>
    </div>
</template>
<script>
import topHeader from '../../views/navigation/topHeader';
import Footer from '../../views/navigation/Footer';
import PreLoader from '../../components/preloader/PreLoader'
export default {
    components: {
      topHeader,
      Footer,
      PreLoader
    },
    data: () => ({
        sharedialog: false,
        isLoading: false,
        cards: [
            { title: 'Vocabulary', src: '/images/vocabulary.png',path: 'vocabulary' },
            { title: 'Reading', src: '/images/reading_1.jpeg',path:'reading' },
            { title: 'Grammar', src: '/images/grammar_1.jpeg',path: 'grammar' },
            { title: 'Videos', src: '/images/videos.png', path: 'videos' },
        ],
        sharing: {
            url: 'https://zhyldyzacademy.kg',
            title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
            description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
            quote: 'The hot reload is so fast it\'s near instant. - Evan You',
            hashtags: 'vuejs,vite,javascript',
            twitterUser: 'youyuxi'
        },
        networks: [
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#EA4335', mar:'10px',pad:'6px' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2',mar:'10px',pad:'6px' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5',mar:'10px',pad:'6px'},
            { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff',mar:'10px',pad:'6px' },
            { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b',mar:'10px',pad:'6px' },
            { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc',mar:'10px',pad:'6px' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2',mar:'10px',pad:'6px' },
            { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8',mar:'10px',pad:'6px' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366',mar:'10px',pad:'6px' },
        ]
    }),
    computed: {
        loggedIn () {
            return this.$store.state.account.user;
        },
    },
    mounted() {
        this.isLoading = true;
        setTimeout(()=>{
            this.isLoading = false;
        },300)
    },
    methods: {
        goTo (url) {
            if(url == 'vocabulary')
            {
                this.$router.push({ path: '/beginner/vocabulary' });
            } 
            else if(url == 'reading') 
            {
                this.$router.push({ path: '/beginner/reading' });
            }
            else if(url == 'videos') 
            {
                this.$router.push({ path: '/beginner/videos' });
            }
            else if(url == 'grammar') 
            {
                this.$router.push({ path: '/beginner/grammar' });
            }
        },
        toHome() {
            this.$router.push({ path: '/' });
        }

    }
}
</script>
<style lang="scss" scoped>
.v-application a {
    color: #fff  !important;
    & span {
        margin:10px;
    }
}
</style>